import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ColorType, getColor } from 'utils'
import { Tooltip } from 'components'
interface ButtonProps {
  onClick?: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: ReactNode
  disabled?: boolean
  isFetching?: boolean
  color?: ColorType
  tooltip?: Record<string, string>
  tooltipLabel?: string
}

const StyledButton = styled.button`
  background-color: ${({color}:ButtonProps) => getColor(color || 'brand')};
  padding: 15px;
  border-radius: 15px;
  transition: ease-in-out .3s all;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &.fetching {
    cursor: wait;
    opacity: 0.75;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: ${({color}:ButtonProps) => getColor(color || 'clearOverlay')};
  }

`

export const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    color,
    isFetching,
    disabled,
    tooltip
  } = props
  return (
    <StyledButton color={color} disabled={isFetching || disabled} className={`${isFetching ? 'fetching' : ''}`} onClick={onClick &&((e) => onClick(e))}>
      { children }
      {tooltip && <Tooltip label={tooltip.label} position={tooltip.position}/> }
    </StyledButton>
  )
}
export default Button
import actions from './actions'

const authOperations = {
  logOut: actions.logOut,
  logIn: actions.logIn,
  getUser: actions.getUser,
  renewToken: actions.renewToken,
  silentRenew: actions.silentRenew,
}

export default authOperations

import { Fragment, useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { InsightsIcon } from 'assets/icons';
import { PistilDataLogo, WelcomeBackground, Insights } from 'assets';
import { authSelectors, hooks } from 'state';
import { Button, Container, LoadingScreen, Text } from 'components';
import { FiChevronRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { dataTrackingOperations } from 'state/ducks/data-tracking';
import StyledWelcome from './styledWelcome';
import { isSameRoleType } from '../utils';

export const Welcome = ({ userManager }: any) => {
  const { useAppSelector, useAppDispatch } = hooks
  const { selectAuth } = authSelectors
  const { trackEvent } = dataTrackingOperations
  let { user } = useAppSelector(selectAuth)
  const [loading, setLoading] = useState(true)
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const originApp = sessionStorage.getItem('originApp')
  const destinationApp = sessionStorage.getItem('destinationApp')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  let name = '';
  let roles: any = [];
  if (user) {
    roles = user?.profile?.role;
    name = user?.profile?.name;
  }
  const [message, setMessage] = useState(
    {
      title: `Welcome ${name.split(' ')[0]}!`,
      subtitle: 'Choose where you want to start today'
    }
  )

  const showInsights = isSameRoleType(roles, 'PistilInsights')

  const cards = [
    {
      key: 'insights',
      shouldShow: showInsights,
      icon: <img src={InsightsIcon} alt="Insights icon"/>,
      title: <img src={Insights} alt="Insights title logo" />,
      button: (
        <Button
          onClick={() => {
            dispatch(trackEvent({ eventCategory: 'switch', values: { appSelection: 'PI' }, event: 'switch_from_menu' }))
            setTimeout(() => { window.location.assign(`${process.env.REACT_APP_INSIGHTS_URL}`) })
          }}
        >
          <Text>GO TO INSIGHTS</Text>
        </Button>
      ),
      redirectUrl: process.env.REACT_APP_INSIGHTS_URL
    },
  ];
  const getActiveCards = () => {
    const activeCards = [];
    if (showInsights) {
      activeCards.push(cards.find((x) => x.key === 'insights'));
    }
    return activeCards;
  };

  const renderCards = () =>
    getActiveCards().map(({ icon, title, button, shouldShow, key }: any) => {
      if (shouldShow) {
        return (
          <Container className={`${key} card`} key={Math.random()}>
            <Container className="icon-container">{icon}</Container>
            <Container className="logo-container">{title}</Container>
            {button}
          </Container>
        );
      }
      return <Fragment key={Math.random()} />;
    });

  useEffect(() => {
    setMessage(prev => ({
      ...prev,
      title: `Welcome ${name.split(' ')[0]}!`,
    }))
    if (user) {
      let hasActiveSubscription;
      const noSuscriptions = !roles || (roles && !roles.length)
      switch (destinationApp) {
        case 'insights':
          hasActiveSubscription = roles?.includes('PistilInsights')
          if (hasActiveSubscription) {
            dispatch(trackEvent({ eventCategory: 'switch', values: { originApp, destinationApp }, event: 'on_redirect_from_origin_app' }))
            setTimeout(() => { window.location.assign(`${process.env.REACT_APP_INSIGHTS_URL}`) })
          } else {
            dispatch(trackEvent({ eventCategory: 'switch', values: { originApp, destinationApp, success: false, message: `don't have an active subscription to Insights` }, event: 'on_redirect_from_origin_app' }))
            setMessage({
              title: `Sorry, you don't have an active subscription to Insights`,
              subtitle: 'Please choose one of your available subscriptions below:'
            })
            setLoading(false)
          }
          break;
        default:
          if (getActiveCards().length === 1) {
            dispatch(trackEvent({ eventCategory: 'switch', values: { originApp, destinationApp: getActiveCards()[0]?.key }, event: 'on_single_subscription' }))
            setTimeout(() => { window.location.assign(`${getActiveCards()[0]?.redirectUrl}`) })
          } else {
            setLoading(false)
          }
          break;
      }
      if (noSuscriptions) {
        window.location.assign(`${process.env.REACT_APP_INSIGHTS_URL}`)
        setLoading(false)
      }
    }
  }, [user])

  if (!loading && roles) return (
    <StyledWelcome className="welcome">
      <Container className="top-bar">
        <Container className="logo">
          <img alt="PistilDataLogo" src={PistilDataLogo} />
        </Container>
        <Container className="user-menu">
          <Container onClick={() => setIsSideMenuOpen(true)}>
            <Text>{name}</Text>
            <BiChevronDown size={24} />
          </Container>
          <Container className={`${isSideMenuOpen ? 'visible ' : ''}outer-area`} onClick={() => setIsSideMenuOpen(false)} />
          <Container className={`${isSideMenuOpen ? 'visible ' : ''}side-menu`} >
            <Container className={`side-menu-content`}>
              <Button onClick={() => navigate('/logout')}>
                <Text>Sign Out</Text><FiChevronRight size={16} />
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className="title-section">
        <Text textStyle='h1'>{message.title}</Text>
        <Text textStyle='h3'>{message.subtitle}</Text>
      </Container>
      <Container className="cards-section">
        {renderCards()}
        <Container className="background">
          <img alt="WelcomeBackground" src={WelcomeBackground} />
        </Container>
      </Container>
    </StyledWelcome>
  );
  return <LoadingScreen />
};

export default Welcome;

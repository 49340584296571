import { UserManager } from 'oidc-client';
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiUtils } from 'state';
import uuidV4 from './sessionId';
import axios from 'axios';

const params = 
  window.location.search?.replace('?', '')
    .split('&')
    .map((objStr) => {
      const objArr = objStr.split('=')
      return {[objArr[0]]: objArr[1]}
    })
    .reduce((acc:any, curr:any) => ({
      ...acc,
      ...curr
    }), {})

const destinationApp =  params.destinationApp || window.location.pathname.replace('/', '') || 'landing'
const originApp = params.originApp || window.location.pathname.replace('/', '') || 'landing'
const action = params.action

if (destinationApp !== 'welcome' && destinationApp !== 'session-close') {  // this is to prevent welcom override the appOrigin since everytime login completes goes to welcome and restart the whole app cycle
  sessionStorage.setItem('destinationApp', destinationApp)
  sessionStorage.setItem('originApp', originApp)
  if (action) {
    sessionStorage.setItem('action', action)
  }
}

const originAppObject:any = {
  sales: "originSales",
  retailers: "originRetailers",
  brands: "originBrands",
  brands2: "originBrands2",
  analytics: "originAnalytics"
}

const originAppScope = originAppObject[destinationApp] || 'originAccount'

const defaultSettings = {
  redirect_uri: `${process.env.REACT_APP_BASE_URL}/welcome`,
  response_type: 'code',
  scope: `openid profile offline_access PistilData.Core.Api roles pistilClientAdmin ${originAppScope}`,
  post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}/session-close`,
  authority: `${process.env.REACT_APP_AUTH_URL}`,
  client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
};
const userManager = new UserManager(defaultSettings)

const logOut = createAsyncThunk('auth/logOut', async (id_token_hint:string, { dispatch }) => {
  userManager.signoutRedirect({id_token_hint})
})

const logIn = createAsyncThunk(
  'auth/logIn',
  async (params: void, { rejectWithValue, dispatch }) => {
    try {
      await userManager.signinRedirect()
    } catch (err) {
      rejectWithValue(err)
    }
  },
)



const getUser = createAsyncThunk(
  'auth/getUser',
  async (user: any, { rejectWithValue, dispatch }) => {
    const sessionId = uuidV4.uuid()
    if (user) {
      return ({ user })
    }
    try {
      let user = await userManager.getUser();
      if (!user) {
        user = await userManager.signinRedirectCallback(window.location.href);
      } 
      return ({ user, sessionId })
    } catch(err) {
      dispatch(logIn())
      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue(err)
    }
  }
)

const renewToken = createAsyncThunk(
  'auth/renewToken',
  async (user: any, { rejectWithValue, dispatch }) => {
    const {redirectUrl} = 
      window.location.search?.replace('?', '')
        .split('&')
        .map((objStr) => {
          const objArr = objStr.split('=')
          return {[objArr[0]]: objArr[1]}
        })
        .reduce((acc:any, curr:any) => ({
          ...acc,
          ...curr
        }), {})
    try {
      const scope = "offline_access profile roles"
      const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URL}/connect/token`, 
        `grant_type=refresh_token&refresh_token=${user.refresh_token}&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&scope=${scope}`,
        {
          headers: { 
            ...apiUtils.getHeaders(),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const newuser = {...user, ...data }
      newuser.expires_at = (data.expires_in) + (new Date().getTime()/1000)
      dispatch(getUser({...newuser, redirectUrl}))
    } catch(err) {
      
      if (!(err as Record<string, string>).response) {
        throw err
      }
      window.location.assign(`${process.env.REACT_APP_BASE_URL}/error?redirectUrl=${redirectUrl}`)
      return rejectWithValue(err)
    }
  }
)

const silentRenew = createAsyncThunk(
  'auth/silentRenew',
  async (user: any, { rejectWithValue, dispatch }) => {
    try {
      const scope = "offline_access profile roles"
      const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URL}/connect/token`, 
        `grant_type=refresh_token&refresh_token=${user.refresh_token}&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&scope=${scope}`,
        {
          headers: { 
            ...apiUtils.getHeaders(),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const newUser = {...user, ...data }
      
      return ({ newUser })
    } catch(err) {
      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue(err)
    }
  }
)

const authActions = {
  logOut,
  logIn,
  getUser,
  renewToken,
  silentRenew,
}

export default authActions

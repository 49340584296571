import React from 'react'
import styled from 'styled-components'

interface ImageProps {
  src: any,
  alt?: string
}

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
`

export const Image = (props: ImageProps) => {
  const { src, alt } = props
  return (
    <StyledImage src={src} alt={alt} />
  )
}
export default Image
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

const selectAuth = (state: RootState) => state.auth

const isAuthenticatedSelector = createSelector(selectAuth, (auth:any) => {
  return !!auth.user && !!auth.token.access_token
})

const subscriptionSelector = createSelector(selectAuth, (auth:any) => {
  return auth.user.subscriptions
})

const authSelectors = {
  selectAuth,
  isAuthenticatedSelector,
  subscriptionSelector
}

export default authSelectors

import React, { ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { Container } from '../containers'
import { LoadingSpinner, PistilDataLogo } from '../../../assets'

const StyledLoadingScreen = styled(Container)`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;


const StyledSpinner = styled(Container)`
  height: 16px;
  width: 16px;
  border: solid 3px rgb(60, 31, 196);
  border-left-color: white;
  border-radius: 50%;
  animation: ${spin} 1s infinite;
`

export const LoadingScreen = () => {
  return (
    <StyledLoadingScreen className={'loading-screen'}>
      <img src={PistilDataLogo} alt="loading-screen" />
      <StyledSpinner className={"loading-spinner"} />
    </StyledLoadingScreen>
  )
}
export default LoadingScreen
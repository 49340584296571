import { LoadingScreen } from '../components'
import { useEffect } from 'react';
import { authOperations, authSelectors, hooks } from '../state'
import { useCookies } from 'react-cookie';
import { cookifyObj, cookifyString, getUserFromCookies, getUserCookieParametersConfig, getSubCookieNames, getUserCookieName } from 'utils';
import { dataTrackingOperations } from 'state/ducks/data-tracking';

// TODO: DO not use yet
export const RefreshToken = () =>  {
  const cookieNames = getSubCookieNames();
  const [,setCookie, removeCookie] = useCookies(Object.values(cookieNames));
  const userCookieObject = getUserFromCookies();
  const userCookieConfig = getUserCookieParametersConfig();

  const { useAppDispatch, useAppSelector } = hooks 
  const { selectAuth } = authSelectors
  const { user } = useAppSelector(selectAuth)

  const dispatch = useAppDispatch()
  const {renewToken} = authOperations

  const setUserCookie = async (cookieLabelName: string, cookieBase64StringValue: string) => {
    setCookie(cookieLabelName, cookieBase64StringValue, userCookieConfig);
  }

  const removeUserCookie = async (cookieLabelName: string) => {
    removeCookie(cookieLabelName, userCookieConfig)
  }

  const removeAndSetUserCookie = async (cookieLabelName: string, cookieBase64StringValue: string) => {
    removeUserCookie(cookieLabelName);
    setUserCookie(cookieLabelName, cookieBase64StringValue);
  }

  const goBackToOriginApp = async () => {
    if (!user) return null
    const { id_token, session_state, refresh_token, token_type } = user
    const { trackEvent } = dataTrackingOperations
    await dispatch(trackEvent({ eventCategory: 'token', values: JSON.stringify({id_token, session_state, refresh_token, token_type}), event: 'token_renew' }))
    await dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ redirectUrl: user.redirectUrl }), event: 'switch_on_token_renew' }))
    return window.location.assign(user.redirectUrl)
  }
  
  useEffect(() => {
    if (user?.redirectUrl) {
      const updatedUser = {...user}
      updatedUser.cookie_expiration_date = new Date().getTime() + (1000 * 3600 * 4) 
      delete updatedUser.redirectUrl

      const {id_token, access_token, refresh_token, ...userData } = user;

      removeAndSetUserCookie(cookieNames.idTokenLabel, cookifyString(id_token));
      removeAndSetUserCookie(cookieNames.accessTokenLabel, cookifyString(access_token));
      removeAndSetUserCookie(cookieNames.refreshTokenLabel, cookifyString(refresh_token));
      removeAndSetUserCookie(cookieNames.userData, cookifyObj(userData));
      
      goBackToOriginApp()
    }
  }, [user])
  
  useEffect(() => {
    dispatch(renewToken(userCookieObject))
  }, [])
  
  return <LoadingScreen />
}

export default RefreshToken
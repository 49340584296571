import { createReducer } from '@reduxjs/toolkit'
import actions from './actions'
import { parseJwtToObject, setCookieInDocument, getSubCookieNames, getUserCookieParametersConfig, cookifyString, cookifyObj } from 'utils/tools'

export type AuthState = {
  isFetching: boolean
  user?: any
  sessionId?: string
}

const initialState: AuthState = {
  isFetching: false,
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.logIn.pending, (state, action) => {
      state.isFetching = true
    })
    .addCase(actions.logIn.fulfilled, (state, action) => {
      state.isFetching = true
    })
    .addCase(actions.silentRenew.pending, (state,action) => {
    })
    .addCase(actions.silentRenew.fulfilled, (state, action) => {
      let newUser = {
        ...action.payload?.newUser,
        cookie_expiration_date: new Date().getTime() + (1000 * 3600 * 4)
      };
      newUser.expires_at = newUser.expires_in + (new Date().getTime()/1000)
      newUser.profile.role = parseJwtToObject(newUser.access_token).role;
      
      let cookieNames = getSubCookieNames();
      let userCookieConfig = getUserCookieParametersConfig();

      let { id_token, access_token, refresh_token, ...userData } = newUser;

      setCookieInDocument(cookieNames.refreshTokenLabel, cookifyString(refresh_token ?? ""), userCookieConfig);
      setCookieInDocument(cookieNames.accessTokenLabel, cookifyString(access_token), userCookieConfig);
      setCookieInDocument(cookieNames.idTokenLabel, cookifyString(id_token), userCookieConfig);
      setCookieInDocument(cookieNames.userData, cookifyObj(userData), userCookieConfig);

      state.user = { ...newUser }
    })
    .addCase(actions.getUser.pending, (state,action) => {
    })
    .addCase(actions.getUser.fulfilled, (state, action) => {
      state.user = { ...action.payload?.user }
      state.sessionId = action.payload?.sessionId
    })
    .addCase(actions.getUser.rejected, (state, action) => {
      state.user = undefined
    })
})

export default authReducer

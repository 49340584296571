import { createReducer, SerializedError } from '@reduxjs/toolkit'
import actions from './actions'

export type AuthState = {
  isFetching: boolean
  sessionId: string
  error?: SerializedError
  message?: any
  application: string
  environment: string
}

const initialState: AuthState = {
  isFetching: false,
  sessionId: '',
  application: '',
  environment: '',
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.trackingInit.pending, (state) => {
      state.isFetching = true
    })
    .addCase(actions.trackingInit.rejected, (state) => {
      state.isFetching = false
    })
    .addCase(actions.trackingInit.fulfilled, (state, action) => {
      state.isFetching = false
      state.environment = `${action.payload.environment}`
      state.sessionId = `${action.payload.sessionId}`
    })
    .addCase(actions.trackEvent.pending, (state) => {
      state.isFetching = true
    })
    .addCase(actions.trackEvent.rejected, (state) => {
      state.isFetching = false
    })
})

export default authReducer

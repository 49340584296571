import { LoadingScreen } from '../components'
import { authOperations, hooks } from 'state'
import { useEffect } from 'react'
import { dataTrackingOperations } from 'state/ducks/data-tracking'
import { getUserFromCookies } from 'utils'

export const Logout = () => {
  const { logOut } = authOperations
  const { useAppDispatch } = hooks
  const dispatch = useAppDispatch()
  const { trackEvent } = dataTrackingOperations

  useEffect(() => {
    const { id_token } = getUserFromCookies()
    dispatch(logOut(id_token))
    dispatch(trackEvent({ eventCategory: 'session', values: true, event: 'logout' }))
  }, [])
  
  return (
    <LoadingScreen />
  )
}

export default Logout
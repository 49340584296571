import { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { BiChevronDown } from 'react-icons/bi';
import { BrandsIcon, RetailIcon, SalesIcon } from 'assets/icons';
import { ErrorBackground, WelcomeBackground } from 'assets';
import { authOperations, authSelectors, hooks } from 'state';
import { Button, Container, Link, LoadingScreen, Text } from 'components';
import { FiChevronRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { dataTrackingOperations } from 'state/ducks/data-tracking';
import { getParams } from 'utils';

const StyledError = styled(Container)`
  height: 100vh;
  padding: 16px;
  justify-content: flex-start;
  overflow: auto;
  box-sizing: border-box;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  .title-section-container {
    padding: 32px;
    padding-top: 56px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: space-between;
    p.h1 {
      color: #1932a0;
      font-size: 32px;
      line-height: 54px;
      @media (max-width: 768px) {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 32px;
      }
    }
    p.h3 {
      text-align: center;
      color: #1932a0;
      font-size: 24px;
      line-height: 24px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .actions-section-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: flex-start;
    align-content: baseline;
    .background-container {
      position: absolute;
      z-index: -1;
      width: 100%;
      display: flex;
      flex: 1;
      bottom: 0;
      img {
        width: 100%;
      }
    }
  }
  button {
    background: #6E51F6;
    &:hover {
      background: #6E51F69b;
    }
    p {
      color: white;
      font-size: 18px;
      
    }
  }
  p {
    margin: 0;
    text-align: center;
  }
  img {
    height: 100%;
  }
`;

export const Error = ({userManager}:any) => {
  const { useAppDispatch } = hooks
  const { trackEvent } = dataTrackingOperations
  const dispatch = useAppDispatch()
  const [message, setmessage] = useState(
    {
      title: `Sorry, this is not working properly. We now know about this mistake and are working to fix it.`,
      subtitle: 'In the meantime, here is what you can do:'
    }
  )

  const onTryAgain = () => {
    dispatch(trackEvent({
      event: 'user_select_retry',
      values: JSON.stringify({ redirectUrl: window.location.href }),
      eventCategory: 'error_page',
    }))
    dispatch(trackEvent({
      event: 'switch_on_error_user_retry',
      values: JSON.stringify({ redirectUrl: window.location.href }),
      eventCategory: 'switch',
    }))
    window.location.assign(getParams().redirectUrl)
  }

  const onSendEmail = () => {
    dispatch(trackEvent({
      event: 'user_select_send_error_email',
      values: JSON.stringify({ redirectUrl: window.location.href }),
      eventCategory: 'error_page',
    }))
  }

  useEffect(() => {
    dispatch(trackEvent({
      event: 'error_page_loaded',
      values: JSON.stringify({ redirectUrl: window.location.href }),
      eventCategory: 'error_page',
    }))
  }, [])

  return (
    <StyledError className="Error">
      <Container className="title-section">
        <Container>
          <img alt="Error" src={ErrorBackground} />
        </Container>
        <Text textStyle='h1'>{message.title}</Text>
        <Text textStyle='h3'>{message.subtitle}</Text>
      </Container>
      <Container className="actions-section">
        <Container>
          <Button onClick={onTryAgain}> <Text>Try Again</Text> </Button>
        </Container>
        <Text>
          or
        </Text>
        <Container onClick={onSendEmail}>
          <Link mailto={`mailto:tech-support@pistildata.com?subject=Issue%20Report&body=Issue%20description.`}>
            <Text>
              Email us at support@pistildata.com and tell us what happened.
            </Text>
          </Link>
        </Container>
        
      </Container>
    </StyledError>
  );
};

export default Error;

import { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import styled from 'styled-components'
import { getColor } from 'utils'
import { Button } from '.'
import { Container, Dropdown, Text } from '..'

const  StyledDropdownMenu = styled(Container)`
  position: relative;
  cursor:pointer;
  .dropdown-body-container  {
    border-radius: 16px;
    border: 1px solid ${getColor('brand')};
    overflow: hidden;
    background: ${getColor('white')};
  }
  .options-container {
    z-index: 2;
    max-height: calc(50vh - 72px);
    overflow: auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    .role-container {
      padding: 4px 16px;
      justify-content: flex-start;
      &:hover {
        background: rgba(0,0,0,.05);
      }
      p {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
      }
    }
    button {
      width: 75px;
      brackground: red;
      align-self: flex-end;
      margin-bottom: 8px;
      margin-right: 8px;
    }
    .message-container {
      max-width: 150px;
      align-self: center;
      text-align: center;
      p {
        font-size: 13px!important;
        line-height: 14px;
        padding-bottom: 8px;
      }
    }
  }
  .dropdown-body-container {
    z-index: 1;
  }
  .trigger-container {
    padding: 4px 16px;
    .label-container {

    }
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .checkbox-container {
    label {
      padding: 4px;
      display: flex;
      width: 100%;
      &:hover {
        background: ${getColor('brandClear')}3b;
      }
    }
  }
`
const Arrow = styled(Container)`
  transition: transform 250ms ease-in-out;
  &.down-container {
    transform: rotate(0deg);
  }
  &.up-container {
    transform: rotate(-180deg);
  }



`

export const DropdownMenu = ({trigger, noSelectionMessage, onHover, allowMultipleSelection, hideCaret, selectedOption, onChange: onChangeProps, label, options}:any) => {
  const initialValues = label?.split(',').reduce((acc:any, curr:string) => ({ ...acc, [curr]: true }), {})
  const [ isExpanded, setIsExpanded ] = useState(false)
  const [ values, setValues ] = useState(initialValues)
  const [ message, setMessage ] = useState('')

  const onClose = () => {
    setIsExpanded(false)
    setValues(initialValues)
  }

  const onChange = (item:any) => {
    onChangeProps(item)
    onClose()
  }

  const onUpdateCategories = () => {
    let newValues = ''
    Object.keys(values).forEach((value: string) => { if (values[value]) newValues = `${newValues ? `${newValues},` : ''}${value}` })
    onChangeProps(newValues)
    onClose()
  }

  const renderTrigger = () => {
    if (trigger) {
      return (
        <Container className={'trigger'} onClick={() => ( !onHover && isExpanded) ? onChange(selectedOption) : setIsExpanded(true)}>
          {trigger}
        </Container>
      )
    }
    return (
      <Container expand className={'trigger'} onClick={() => ( !onHover && isExpanded) ? onChange(selectedOption) : setIsExpanded(true)}> 
        {
          label && (
            <Container className={'label'}>
              <Text color='brand'>{label.replaceAll(',',', ')}</Text>
            </Container>
          )
        }
        { selectedOption?.label && <Text>{selectedOption.label}</Text> }
        { selectedOption?.icon }
        { !hideCaret && <Arrow className={`arrow ${!isExpanded ? 'down' : 'up'}`} ><FiChevronDown size={20}/></Arrow> }
      </Container>
    )
  }

  useEffect(() => {
    let hasValues = false
    Object.keys(values || {}).forEach((val: any) => {
      if (values[val]) {
        hasValues = true
      }
    })

    if (hasValues) {
      setMessage('')
    } else {
      setMessage(noSelectionMessage)

    }
  }, [values])
  

  if (allowMultipleSelection) {
    return (
      <StyledDropdownMenu className={`dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}> 
          <Container className={'options'}>
            {
              options.map((option:Record<string, any>) => {
                const onCheckOption = (e:any) => {
                  setValues({ ...values, [option.key]: e.target.checked })
                }
                const isChecked = values[option.key] ? true : false
                return (<Container key={option.key} className={'checkbox'} >
                  <label>
                    <input type={'checkbox'} checked={isChecked} onChange={onCheckOption} />
                    <Text>{option.label}</Text> 
                  </label>
                </Container>)
              })
            }
            {
              message 
              ? (
                <Container className={'message'}>
                  <Text>{message}</Text>
                </Container>
              )
              : (
                <Button onClick={onUpdateCategories}>
                  <Text>{'Apply'}</Text>
                </Button>
              )
            }
          </Container>
        </Dropdown>
      </StyledDropdownMenu>
    )
  }

  return (
    <div
    style={{
      display: 'flex',
      height: '100%',
    }}
      onMouseLeave={() => {
        if (onHover) {
          onHover()
          return setIsExpanded(false)
        }
      }}
      onMouseEnter={() =>  {
        if (onHover) {
          onHover()
          return setIsExpanded(true)
        }
      }}
    >
      <StyledDropdownMenu className={`dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}> 
          <Container className={'options'}>
            {options.map((option: Record<string, any>) =>  
              <Container key={option.key} className={'role'} onClick={() => onChange(option)}>
                <Text>{option.label}</Text> 
              </Container>
            )}
          </Container>
        </Dropdown>
      </StyledDropdownMenu>
    </div>
  )
}

export default DropdownMenu

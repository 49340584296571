import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './rootStyles.css'
import { Provider } from 'react-redux';
import { store } from './state';
const rootNode = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootNode);
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
);
reportWebVitals();



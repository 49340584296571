import styled, { css } from 'styled-components'
import { ColorType, getColor } from '../../../utils'

interface textProps {
  children: string
  textStyle?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'button' | 's1' | 's2' | 'support'
  color?: ColorType
  textAlign?: 'left' | 'center' | 'right'
}

const StyledText = styled.p`
`

export const Text = ({children, textStyle="h7", color, textAlign}: textProps) => {
  return (
    <StyledText className={textStyle}>{children}</StyledText>
  )
}
export default Text
import {
  ACCESS_TOKEN_COOKIE_SUFFIX,
  REFRESH_TOKEN_COOKIE_SUFFIX,
  ID_TOKEN_COOKIE_SUFFIX,
  USER_DATA_COOKIE_SUFFIX,
  USER_COOKIE_PREFIX,
} from 'constants/cookieNames';

const cookifyObject = (object: any) => {
  return window.btoa(JSON.stringify(object))
}

const cookifyString = (cookieValue: string) => {
  return window.btoa(cookieValue);
}


const getUserCookieName = () => `${process.env.REACT_APP_ENVIRONMENT}`.includes('prod') ? USER_COOKIE_PREFIX : `${USER_COOKIE_PREFIX}${process.env.REACT_APP_ENVIRONMENT}`;
const getUserCookieParametersConfig : any = () => ({
  path: '/',
  domain:
    window.location.host === 'localhost:3200'
      ? undefined
      : 'pistildata.com',
  sameSite: 'none',
  secure: true,
});

const cookieLabel = getUserCookieName() ;

const getSubCookieNames = () => ({
  idTokenLabel: `${cookieLabel}${ID_TOKEN_COOKIE_SUFFIX}`,
  accessTokenLabel: `${cookieLabel}${ACCESS_TOKEN_COOKIE_SUFFIX}`,
  refreshTokenLabel: `${cookieLabel}${REFRESH_TOKEN_COOKIE_SUFFIX}`,
  userData: `${cookieLabel}${USER_DATA_COOKIE_SUFFIX}`
});

const subCookieNames = getSubCookieNames();

const cookiesArray:any = document.cookie.split(';').reduce((acc, curr) => {
  const [key, value] = curr?.replaceAll('"', '')?.replaceAll(' ', '')?.split('=')
  return ({
      ...acc, [key]: value,
  })
},{})

const base64Cookie = cookiesArray[cookieLabel]?.replaceAll('%3D', '=');
const base64idTokenCookie = cookiesArray[subCookieNames.idTokenLabel]?.replaceAll('%3D', '=');
const base64accessTokenCookie = cookiesArray[subCookieNames.accessTokenLabel]?.replaceAll('%3D', '=');
const base64refreshTokenCookie = cookiesArray[subCookieNames.refreshTokenLabel]?.replaceAll('%3D', '=');
const base64DataCookie = cookiesArray[subCookieNames.userData]?.replaceAll('%3D', '=');


const getUserFromCookies = () => {
  if (cookiesArray && base64idTokenCookie && base64accessTokenCookie && base64refreshTokenCookie && base64DataCookie) {
    return {
      id_token: atob(base64idTokenCookie),
      access_token: atob(base64accessTokenCookie),
      refresh_token: atob(base64refreshTokenCookie),
      ...JSON.parse(atob(base64DataCookie))
    };
  }
  // TODO: Remove after forcing transition to subcookies
  if(cookiesArray && base64Cookie) {
    const { id_token, access_token, refresh_token, ...userData } = JSON.parse(atob(`${base64Cookie}`));

    return {
      id_token: id_token,
      access_token: access_token,
      refresh_token: refresh_token,
      ... userData
    }
  }
}

const replaceLast = (str:string, pattern:any, replacement:string) => {
  const match =
    typeof pattern === 'string'
      ? pattern
      : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
  if (!match) return str;
  const last = str.lastIndexOf(match);
  return last !== -1
    ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
    : str;
};

const getParams = () =>
  window.location.search?.replace('?', '')
    .split('&')
    .map((objStr) => {
      const objArr = objStr.split('=')
      return {[objArr[0]]: objArr[1]}
    })
    .reduce((acc:any, curr:any) => ({
      ...acc,
      ...curr
    }), {})


const compareRoleType = (role: string[], roleType: string) => {
  return role?.find((roleString: string) => roleString === roleType)
}

const isSameRoleType = (role: string[], roleType: string) => {
  return role && Array.isArray(role) ? compareRoleType(role, roleType) : role === roleType
}

const parseJwtToObject = (token : string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}



function getCookie(name : string) {
  const cookieArray = document.cookie.split(';');
      for (const cookie of cookieArray) {
          const [cookieName, cookieValue] = cookie.trim().split('=');
          if (cookieName === name) {
              return decodeURIComponent(cookieValue);
          }
      }
  return null;
}

function setCookie(name : string, value : string, options : any) {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  if (options.domain) {
      cookieString += `; ;domain=${options.domain}`;
  }
  if (options.expires) {
      cookieString += `; expires=${options.expires.toUTCString()}`;
  }
  if (options.path) {
      cookieString += `; path=${options.path}`;
  }
  if (options.sameSite){
      cookieString += `; SameSite=${options.sameSite}`
  }
  if (options.secure) {
      cookieString += "; Secure"
  }
  document.cookie = cookieString;
}

function removeCookie(name : string, options : any) {
  const expires = new Date(0);
  setCookie(name, '', { ...options, expires });
}

export {
  cookifyString,
  cookifyObject as cookifyObj,
  getParams,
  replaceLast,
  getUserCookieName,
  getUserFromCookies,
  isSameRoleType,
  getUserCookieParametersConfig,
  getSubCookieNames,
  parseJwtToObject,
  getCookie as getCookieInDocument,
  setCookie as setCookieInDocument,
  removeCookie as removeCookieInDocument
}
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isMobile } from "react-device-detect"
import axios from 'axios';
import store from '../../store';
import { UAParser } from 'ua-parser-js'

const environment = process.env.REACT_APP_ENVIRONMENT
const userAgentParser = new UAParser(navigator.userAgent);
const application = 'PAM'

const trackingInit = createAsyncThunk('data-tracking/trackingInit', async (params: void, { rejectWithValue, dispatch }) => {
  const { user, sessionId: authSessionId } = store.getState().auth
  const sessionId = user?.session_id || authSessionId
  const { profile: { sub: userGuid} } = user
  try {
      const queryParams = {
        sessionId,
        userGuid,
        application, 
        deviceType: isMobile ? 'mobile' : 'desktop',
        device: navigator.userAgent,
        operativeSystem: [userAgentParser.getOS().name, userAgentParser.getOS().version].filter(Boolean).join(" "),
        browser: userAgentParser.getBrowser().name,
        creationDatetimeUtc: (new Date()).toISOString(),
        environment
      }
      await axios.post(`${process.env.REACT_APP_DATA_TRACKING_API_URL}/Metadata_Post`,
        queryParams,
        {
          headers: {
            'x-functions-key': `${process.env.REACT_APP_DATA_TRACKING_API_KEY}`
          }
        }
      );
      return ({ sessionId, application, environment })
    } catch (err) {
      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue(err)
    }
  }
)

const trackEvent = createAsyncThunk('data-tracking/trackEvent', async (props: any, { rejectWithValue, dispatch }) => {
  const { eventCategory, event, values, userGuid: userGuid_ } = props;
  const dateTimeUtc = (new Date()).toISOString()
  const { user, sessionId: authSessionId } = store.getState().auth
  const sessionId = user?.session_id || authSessionId
  const { profile: { sub: userGuid} } = user

    try {
      if (!sessionId) {
        throw 'noSessionId'
      }
      const params = {
        dateTimeUtc,
        eventCategory,
        event,
        values: typeof values === 'object' ? JSON.stringify(values) : values,
        sessionId,
        application,
        environment: `${process.env.REACT_APP_ENVIRONMENT}`,
        userGuid: userGuid_ || userGuid
      }
      await axios.post(`${process.env["REACT_APP_DATA_TRACKING_API_URL"]}/Event_Tracking_Post`,
        params,
        {
          headers: {
            'x-functions-key': `${process.env["REACT_APP_DATA_TRACKING_API_KEY"]}`
          }
        }
      );
      } catch (err) {
        if (!(err as Record<string, string>).response) {
          throw err
        }
        return rejectWithValue(err)
      }
  } 
)

const dataTrackingActions = {
  trackingInit,
  trackEvent,
}

export default dataTrackingActions

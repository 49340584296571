export type AssetType = 
  | 'loginBackground'
  | 'name'

export type ColorType =
  | 'brand'
  | 'clearOverlay'
  | 'white'
  | 'brandDark'
  | 'border'
  | 'text'
  | 'textClear'
  | 'error'
  | 'darkOverlay'
  | 'inactive'
  | 'brandClear'
  | 'grayClear'
  | 'success'
  | 'sigmaBorder'

export const getColor = (color: ColorType) => () => {
  const theme:any = {
      white: '#ffffff',
      border: '#7474745e',
      inactive: '#b8b8b8',
      text: '#1c1c1c',
      textClear: '#D6D4E0',
      error: '#A62353',
      darkOverlay: 'rgba(0, 0, 0, 0.8)',
      clearOverlay: 'rgba(255, 255, 255, .2)',
      grayClear: '#F5F5F5',
      sigmaBorder: '#d7d4e1',
      success: '#05EB97'
  }
    return (theme[color])
}

import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { Text } from '.'

interface LinkProps {
  to?: string
  children?: ReactNode
  mailto?: string
}

const StyledLink = styled(RouterLink)`
  text-decoration: none;
  display: flex;
`
const StyledA = styled.a`
  display: flex;
`

export const Link = (props: LinkProps) => {
  const { to = '', children, mailto } = props
  if (mailto) {
    return <StyledA href={mailto}>{children }</StyledA> 
  }
  return (
    <StyledLink to={to}>{children}</StyledLink>
  )
}
export default Link
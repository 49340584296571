import './rootStyles.css';
import { Container } from './components'
import { Login, Logout, RefreshToken, SessionClose, Welcome, Error } from './pages'
import { Navigate, Route, Routes } from "react-router-dom";
import styled from 'styled-components'
import { PrivateRoute } from 'components/modules';
import { authSelectors, hooks } from 'state';
import { migrateCookies } from 'utils/cookieMigration';
import { LoadingScreen } from 'components/shared'

const StyledApp = styled(Container)`
`

const App = () => { 
  const { selectAuth } = authSelectors
  const { useAppSelector } = hooks
  const { isFetching } = useAppSelector(selectAuth)
  
  migrateCookies();

  if (isFetching) return <LoadingScreen />
  return (
      <>
        <StyledApp expand className="main">
          <Routes>
            <Route path='/' element={<PrivateRoute />} >
              <Route index element={<Welcome />} />
              <Route path="/welcome" element={<Welcome />} />
              <Route path='/time-out' element={<Logout />} >
                <Route path="*" element={<Navigate to={"/logout"}/>} />
              </Route>
              <Route path='/logout' element={<Logout />} >
                <Route path="*" element={<Navigate to={"/logout"}/>} />
              </Route>
              <Route path="/error" element={<Error />} />
            </Route>
            <Route path='/login' element={<Login  />} />
            <Route path='/session-close' element={<SessionClose />} />
            <Route path='/refresh-token' element={<RefreshToken />} />
            <Route path="*" element={<Navigate to={"/"}/>} />
          </Routes>
        </StyledApp>
      </>
  );
}

export default App;



import { Container, Text } from '..';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { getColor } from '../../../utils';

interface TooltipProps {
  label: string;
  position?: string;
}

const StyledTooltipContainer = styled(Container)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  &:hover {
    .tooltip-container {
      opacity: 1;
      visibility: visible;
    }
  }
  &.left {
    .tooltip-container {
      right: 100%;
      transform: translateX(-16px);
    }
    
  }
  &.bottom {
    .tooltip-container {
      bottom: calc(-100% - 8px);
    }
  }
  .tooltip-container {
    transition: .3s ease-in-out all;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    background: ${getColor('darkOverlay')};
    color: white;
    padding: 8px;
    position: absolute;
    p {
      color: ${getColor('white')};
      white-space: nowrap;
    }
  }
`;

export const Tooltip = ({ label, position }: TooltipProps) => {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <StyledTooltipContainer className={position ?`${position} tooltip-trigger` : 'tooltip-trigger'}>
      <Container className={'tooltip'}>
        <Text>
          {capitalizeFirstLetter(label)}
        </Text>
      </Container>
    </StyledTooltipContainer>
  );
};
export default Tooltip;

import { Container } from "../components"
import styled from "styled-components";
import { useEffect } from "react";
import { authOperations, hooks } from "state";

const StyledLogin = styled(Container)`
`

export const Login = () => {
  const { logIn } = authOperations
  const { useAppDispatch } = hooks
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(logIn())
  }, [])
  
  return (
    <StyledLogin className="login">
      login
    </StyledLogin>
  );
};

export default Login;

import { Container, LoadingScreen, Text } from '../components'
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { PistilDataLogo, WelcomeBackground } from 'assets';
import { useNavigate } from 'react-router-dom';
import { getSubCookieNames, getUserCookieParametersConfig, getUserCookieName, getUserFromCookies } from 'utils/tools';
import useRenderCard from '../utils/hooks/useRenderCard';
import StyledSessionClose from './session-close-styled'

export const SessionClose = () =>  {
  const { renderCards } = useRenderCard()
  const navigate = useNavigate()

  const cookieNames = getSubCookieNames();
  // TODO: Remove after forcing transition to subcookies
  const baseCookieNameForEnvironment = getUserCookieName();
  const [,,removeCookie] = useCookies(Object.values(cookieNames));
  const userCookieConfig = getUserCookieParametersConfig();

  /* const [user, setUser] = useState() */
  const [title, setTitle] = useState("You are logged out")
  const [tagLine, setTagLine] = useState("Click below to log back in")
  const [loading, setLoading] = useState(true)
  const destinationApp = sessionStorage.getItem('destinationApp')
  const originApp = sessionStorage.getItem('originApp')
  const action = sessionStorage.getItem('action')
  const timedOut = destinationApp && destinationApp === 'time-out'

  const removeUserCookie = async (cookieLabelName: string) => {
    removeCookie(cookieLabelName, userCookieConfig)
  }

  useEffect(() => {
    const userCookiesExists = getUserFromCookies();
    /* setUser(userCookieObject) */
    if (timedOut) {
      setTitle('Your session has been closed due inactivity')
    }
    switch (action) {
      case 'showLogin':
        navigate(`/${originApp}`)
        break;
      default:
        break;
    }
    if (!userCookiesExists) {
      window.location.assign(`${process.env.REACT_APP_BASE_URL}`)
    } else {
      // TODO: Remove after forcing transition to subcookies
      removeUserCookie(baseCookieNameForEnvironment);

      removeUserCookie(cookieNames.accessTokenLabel);
      removeUserCookie(cookieNames.refreshTokenLabel);
      removeUserCookie(cookieNames.idTokenLabel);
      removeUserCookie(cookieNames.userData);
      
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
  }, [])
  
  if (!loading) return (
    <StyledSessionClose expand className={'SessionClose'}>
      <Container className="top-bar">
        <Container className="logo">
          <img alt="PistilDataLogo" src={PistilDataLogo} />
        </Container>
        
      </Container>
      <Container className="title-section">
        <Text textStyle='h1'>{title}</Text>
        <Text textStyle='h3'>{tagLine}</Text>
      </Container>
      <Container className="cards-section">
        {renderCards()}
        <Container className="background">
          <img alt="WelcomeBackground" src={WelcomeBackground} />
        </Container>
      </Container>
    </StyledSessionClose>
  )
  return <LoadingScreen />
}

export default SessionClose
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Container, Text } from 'components'
import { getUserFromCookies, isSameRoleType } from '../tools'
import { Insights , InsightsIcon} from 'assets';

export default function useRenderCard() {
  const [user, setUser] = useState<any>();
  let roles: any[] = [];

  if (user) {
    roles = (user as any)?.profile.role;
  }

  const showInsights = isSameRoleType(roles, 'PistilInsights');
  const cards = [
    {
      key: 'insights',
      shouldShow: showInsights,
      icon: <img src={InsightsIcon} alt="Insights Icon" />,
      title: <img src={Insights} alt="Insights title logo" />,
      button: (
        <Button
          onClick={() => {
            setTimeout(() => {
              window.location.assign(`${process.env.REACT_APP_INSIGHTS_URL}`);
            });
          }}
        >
          <Text>GO TO INSIGHTS</Text>
        </Button>
      ),
      redirectUrl: process.env.REACT_APP_INSIGHTS_URL,
    },
  ];

  const getActiveCards = () => {
    const activeCards = [];
    if (showInsights) {
      activeCards.push(cards.find(x => x.key === 'insights'));
    }
    return activeCards;
  };

  useEffect(() => {
    setUser(getUserFromCookies());
  }, []);

  useEffect(() => {
    if (user && getActiveCards().length === 1) {
      window.location.assign(`${getActiveCards()[0]?.redirectUrl}`);
    }
  }, [user]);

  const renderCards = () => {
    return getActiveCards().map(
      ({ icon, title, button, shouldShow, key }: any) => {
        if (shouldShow) {
          return (
            <Container className={`${key} card`} key={Math.random()}>
              <Container className="icon-container">{icon}</Container>
              <Container className="logo-container">{title}</Container>
              {button}
            </Container>
          );
        }
        return <Fragment key={Math.random()} />;
      }
    );
  };

  return { renderCards, getActiveCards };
}


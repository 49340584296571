import { cookifyObj, cookifyString, getSubCookieNames, getUserCookieName, getUserCookieParametersConfig, getUserFromCookies, getCookieInDocument, setCookieInDocument, removeCookieInDocument } from './tools';

const migrateCookies = () => {
    const newCookieNames = getSubCookieNames();
    const legacyCookieName = getUserCookieName();
    const userCookieConfig = getUserCookieParametersConfig();

    const legacyCookieData = getCookieInDocument(legacyCookieName);
    const idTokenCookieData = getCookieInDocument(newCookieNames.idTokenLabel);
    const accessTokenCookieData = getCookieInDocument(newCookieNames.accessTokenLabel);
    const refreshTokenCookieData = getCookieInDocument(newCookieNames.refreshTokenLabel);
    const userDataCookieData = getCookieInDocument(newCookieNames.userData);

    const userFromCookies = getUserFromCookies();
    if(userFromCookies)
    {
        const { id_token, access_token, refresh_token, ...userData } = userFromCookies;

        if (legacyCookieData && userFromCookies) {
            const isNotLiteralNullOrUndefined = (value : string) => value !== 'null' && value !== 'undefined';
    
            if (!userDataCookieData && userData) {
                setCookieInDocument(newCookieNames.userData, cookifyObj(userData), userCookieConfig);
            }
            if (!idTokenCookieData && id_token && isNotLiteralNullOrUndefined(id_token)) {
                setCookieInDocument(newCookieNames.idTokenLabel, cookifyString(id_token), userCookieConfig);
            }
            if (!accessTokenCookieData && access_token && isNotLiteralNullOrUndefined(access_token)) {
                setCookieInDocument(newCookieNames.accessTokenLabel, cookifyString(access_token), userCookieConfig);
            }
            if (!refreshTokenCookieData && refresh_token && isNotLiteralNullOrUndefined(refresh_token)) {
                setCookieInDocument(newCookieNames.refreshTokenLabel, cookifyString(refresh_token), userCookieConfig);
            }
            
            removeCookieInDocument(legacyCookieName, userCookieConfig);
        }
    }
};

export {
    migrateCookies
};
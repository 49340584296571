import styled from 'styled-components'
import { Container } from 'components'

const StyledSessionClose = styled(Container)`
  height: 100vh;
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  p {
    text-align: center;
  }
  .top-bar-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px;
    padding-bottom: 0;
    .user-menu-container {
      display: flex;
      align-items: center;
      .side-menu-container {
        border: 1px solid #C1C1C1;
        border-radius: 16px;
        position: absolute;
        top: 72px;
        right: 24px;
        min-width: 250px;
        background: rgb(251, 250, 255);
        flex-direction: column;
        align-items: stretch;
        transition: ease-in-out .3s all;
        transform: translateY(-16px);
        opacity: 0.0;
        visibility: hidden;
        &.visible {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
        .side-menu-content-container {
          flex: 1;
          max-height: 100%;
          flex-direction: column;
          overflow: auto;
          padding: 0px 24px;
          align-items: center;
          justify-content: flex-start;
          button {
            padding: 0;
            align-items: center;
            justify-content: space-between;
            border-radius: 0;
            background: rgb(251, 250, 255);
            a{
              text-decoration: none;
              
              
            }
              p, svg {
                color: #A62353;
                font-size: 18px;
              }
            border-bottom: 1px solid ;
            &:nth-last-of-type(1) {
              border-bottom: 0px;
                &:hover {
                  p, svg {
                  }
                }
            }
          }
        }
      }
      .outer-area-container {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        &.visible {
          visibility: visible;
        }
      }
    }
  }
  .title-section-container {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    p.h1 {
      color: #1932a0;
      padding-top: 82px;
      font-size: 48px;
      line-height: 54px;
      @media (max-width: 768px) {
        padding-top: 0px;
        margin: 0;
        padding-bottom: 0px;
        font-size: 32px;
      }
    }
    p.h3 {
      color: #1932a0;
      font-size: 24px;
      padding-bottom: 44px;
      line-height: 24px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .cards-section-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 40px;
    align-content: baseline;
    .background-container {
      position: absolute;
      z-index: -1;
      width: 100%;
      display: flex;
      flex: 1;
      bottom: 0;
      img {
        width: 100%;
      }
    }
    .card-container {
      flex: 1;
      max-width: 250px;
      @media (max-width: 768px) {
        max-width: 100%;
        padding: 4px;
      }
      justify-content: space-between;
      box-sizing: border-box;
      display: flex;
      background: white;
      align-items: center;
      &.sales {
        color: #6E51F6;
        button {
          background: #6E51F6;
        }
      }
      &.brands {
        color: #017381;
        button {
          background: #017381;
        }
      }
      &.brands2 {
        color: #017381;
        button {
          background: #017381;
        }
      }
      &.analytics {
        color: #f25757;
        button {
          background: #f25757;
        }
      }
      &.retail {
        color: #0057B2;
        button {
          background: #0057B2;
        }
      }
      &.insights {
        color: #0057B2;
        button {
          background: #0057B2;
        }
      }
      filter: drop-shadow(0px 4px 20px rgba(62, 62, 62, 0.37));
      padding: 32px;
      gap: 8px;
      border: 1px solid;
      border-radius: 40px;
      flex-direction: column;
      .app-icon-container {
        padding-bottom: 4px;
      }
      .app-title-container {
      }
      button {
        padding: 0px 24px;
        @media (max-width: 768px) {
          width: 55px;
          padding: 0px;
          p {
            width: 3ch;
            overflow: hidden;
          }
        }
        border-radius: 32px;
        border: none;
        white-space: nowrap;
        p {
          line-height: 16px;
          font-weight: 900;
          font-size: 16px;
          color: white;
        }
        &:hover {
          cursor: pointer;
        }
      }
      @media (max-width: 768px) {
        flex-direction: row;
      }
    }
  }
`
export default StyledSessionClose
